import styled from "styled-components";
import { Form } from "@unform/web";
import { MdCheck } from "react-icons/md";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ContainerLeft = styled.div`
  background-color: #FFFFFF;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E"); */
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  /* background-image: url("https://images.pexels.com/photos/5088178/pexels-photo-5088178.jpeg"); */
  width: 100%;
  height: 100vh;
  /* background-size: cover; */
  align-items: center;
  /* background-attachment: fixed; */

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 310px;
  justify-content: space-around;
  @media (max-width: 720px) {
    width: initial;
  }
`;

const ContainerAuth = styled.div`
  background-color: #fff;
  border-radius: 5px;
  margin-right: 30px;
  padding: 60px 30px;
  position: relative;
  @media (max-width: 1024px) {
    /* width: 100%; */
  }
  @media (max-width: 720px) {
    width: 100%;
    height: 100%;
    margin-right: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
  }
`;

const BoxText = styled.div`
  margin-bottom: 25px;
`;

const SentEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 400px;
`;
const IconSentEmail = styled(MdCheck)`
  font-size: 4em;
  color: green;
`;

const BoxBackLogin = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 15px;
`;

export {
  Container,
  ContainerLeft,
  ContainerAuth,
  FormContainer,
  BoxText,
  SentEmail,
  IconSentEmail,
  BoxBackLogin,
};
